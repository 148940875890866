#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #0b0a0e;
  z-index: 9999;
  transition: 0.4s all;
  opacity: 1;
}

.loaded {
  opacity: 0 !important;
  z-index: -9999 !important;
  position: absolute !important;
  overflow: auto !important;
  background: transparent !important;
}

.loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #efff04;
  animation: spin 2s linear infinite;
}

.loader::before {
  content: '';
  position: absolute;
  inset: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #efff04; // inny kolor powinien byc;
  animation: spin 3s linear infinite;
}

.loader::after {
  content: '';
  position: absolute;
  inset: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #efff04; //jescze inny;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
